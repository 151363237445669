import React, { useState, useEffect } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";

const Step1PersonalAccidentProductForm = ({
  formDataStep3PersonalAccidentProduct,
  setFormDataStep3PersonalAccidentProduct,
  errors,
  setErrors,
}) => {
  // Add Functionality of Personal Accident Check Duties
  const [checkedPersonalAccidentDuties, setCheckedPersonalAccidentDuties] = useState({
    officeDuties: false,
    officeDutiesSiteVisits: false,
    supervisionWorking: false,
    commercialTraveler: false,
    manualWorker: false,
    other: false,
  });
  const isAnyPersonalAccidentDutySelected = () => {
    return Object.values(checkedPersonalAccidentDuties).some(value => value === true);
  };
  
  // Define a function to handle chkDuty checkbox changes
  const handleCheckboxPersonalAccidentDuties = (event) => {
    const { name, checked } = event.target;
    setCheckedPersonalAccidentDuties((prevCheckedPersonalAccidentDuties) => ({
      ...prevCheckedPersonalAccidentDuties,
      [name]: checked,
    }));
    setFormDataStep3PersonalAccidentProduct({...formDataStep3PersonalAccidentProduct, chkDuty: checkedPersonalAccidentDuties})
  };

  // Define a function to handle radio button changes
  const handleRadioButtons = (event) => {
    const { name, value } = event.target;
    setFormDataStep3PersonalAccidentProduct(
      prevformDataStep3PersonalAccidentProduct => ({
        ...prevformDataStep3PersonalAccidentProduct,
        radioButtons: {...prevformDataStep3PersonalAccidentProduct.radioButtons, [name]: value}
      })
    )
  };

  // Define a function to handle chkPADeclined checkbox changes
  const handleCheckBoxPersonalAccidentDeclined = (event) => {
    const { name, checked } = event.target;
    setFormDataStep3PersonalAccidentProduct(
      prevformDataStep3PersonalAccidentProduct => ({
        ...prevformDataStep3PersonalAccidentProduct,
        chkPADeclined: {...prevformDataStep3PersonalAccidentProduct.chkPADeclined, [name]: checked}
      })
    )
  };
  const isAnyPersonalAccidentDeclinedSelected = () => {
    return Object.values(formDataStep3PersonalAccidentProduct.chkPADeclined).some(value => value === true);
  };

  return (
    // {/* Form Related to Personal Accident Product */}
    <div className="shadow-div mt-4">
      <div className='mb-4'>
        <h5 className='font-semibold'>Personal Accident Questionnaire</h5>
      </div>
      {/* Part B */}
      <div className="grid grid-cols-1 mb-2 gap-2">
        <form className="flex-1">
          {/* Q1 */}
          {/* Text Duty */}
          <div className="grid grid-cols-1 mb-2">
            <label className="mb-2">What actual duties did you perform? (If more than one, state all)</label>
            <TextField variant="outlined" fullWidth required 
              value={formDataStep3PersonalAccidentProduct.txtDuty}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, txtDuty: e.target.value });
              if (errors.txtDuty) {
                  setErrors({ ...errors,txtDuty: '' });
              }
              }}
              error={!!errors.txtDuty}
              helperText={errors.txtDuty}
            />
          </div>

          {/* Check Duty */}
          <div className="grid grid-cols-1 mb-2">
            <label>Tick against your description</label>
            <FormControlLabel
              control={
                <Checkbox
                  name="officeDuties"
                  checked={checkedPersonalAccidentDuties.officeDuties}
                  onChange={handleCheckboxPersonalAccidentDuties}
                />
              }
              label="Office duties"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="officeDutiesSiteVisits"
                  checked={checkedPersonalAccidentDuties.officeDutiesSiteVisits}
                  onChange={handleCheckboxPersonalAccidentDuties}
                />
              }
              label="Office duties with site visits"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="supervisionWorking"
                  checked={checkedPersonalAccidentDuties.supervisionWorking}
                  onChange={handleCheckboxPersonalAccidentDuties}
                />
              }
              label="Supervision and working"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="commercialTraveler"
                  checked={checkedPersonalAccidentDuties.commercialTraveler}
                  onChange={handleCheckboxPersonalAccidentDuties}
                />
              }
              label="Commercial traveler"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="manualWorker"
                  checked={checkedPersonalAccidentDuties.manualWorker}
                  onChange={handleCheckboxPersonalAccidentDuties}
                />
              }
              label="Manual Worker"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="other"
                  checked={checkedPersonalAccidentDuties.other}
                  onChange={handleCheckboxPersonalAccidentDuties}
                />
              }
              label="Other"
            />
            {checkedPersonalAccidentDuties.other && (
              <TextField label="Other (please specify)" variant="outlined" 
                value={formDataStep3PersonalAccidentProduct.chkDutyOther}
                required
                onChange={(e) => {
                setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, chkDutyOther: e.target.value });
                if (errors.chkDutyOther) {
                    setErrors({ ...errors,chkDutyOther: '' });
                }
                }}
                error={!!errors.chkDutyOther}
                helperText={errors.chkDutyOther}
              />
            )}
          </div>

          <hr className="my-4 bg-gray-900"   />

          {/* Q2 */}
          {/* Impairments */}
          <div className="grid grid-cols-1 mb-4">
            <label>Do you suffer from any sight, hearing or any other impairment?</label>
            <RadioGroup name='impairments' value={formDataStep3PersonalAccidentProduct.radioButtons.impairments} onChange={handleRadioButtons}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            {formDataStep3PersonalAccidentProduct.radioButtons.impairments === 'yes' && 
            <TextField label="Give details" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.impairments}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, impairments: e.target.value });
              }}
            />}
          </div>

          {/* Serious injury or illness */}
          <div className="grid grid-cols-1 mb-4">
            <label>Have you ever suffered from any serious injury or illness?</label>
            <RadioGroup name='seriousInjuryIllness' value={formDataStep3PersonalAccidentProduct.radioButtons.seriousInjuryIllness} onChange={handleRadioButtons}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            {formDataStep3PersonalAccidentProduct.radioButtons.seriousInjuryIllness === 'yes' && 
            <TextField label="Give details" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.seriousInjuryIllness}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, seriousInjuryIllness: e.target.value });
              }}
            />}
          </div>

          {/* Present health */}
          <div className="grid grid-cols-1 mb-4">
            <label>Do you, at present, have any health problem or physical disability?</label>
            <RadioGroup name='currentHealth' value={formDataStep3PersonalAccidentProduct.radioButtons.currentHealth} onChange={handleRadioButtons}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            {formDataStep3PersonalAccidentProduct.radioButtons.currentHealth === 'yes' && 
            <TextField label="Give details" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.currentHealth}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, currentHealth: e.target.value });
              }}
            />}
          </div>

          <hr className="my-4 bg-gray-900"   />
          
          {/* Q3 */}
          {/* Hazardous sporting activities and pastimes */}
          <div className="grid grid-cols-1 mb-4">
            <label>Do you engage in hazardous sporting activities or pastimes?</label>
            <RadioGroup name='hazardousSportsActivities' value={formDataStep3PersonalAccidentProduct.radioButtons.hazardousSportsActivities} onChange={handleRadioButtons}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            {formDataStep3PersonalAccidentProduct.radioButtons.hazardousSportsActivities === 'yes' && 
            <TextField label="Give details" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.hazardousSportsActivities}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, hazardousSportsActivities: e.target.value });
              }}
            />}
            {formDataStep3PersonalAccidentProduct.radioButtons.hazardousSportsActivities && 
            <p>Note: The following activities and others of a similar nature are not covered unless on a special arrangement, in which case additional premium will be charged: -
            Aqualung diving, boxing, climbing or mountaineering necessitating the use of ropes or guides, football (except amateur football), hang gliding, wild hunting, ice hockey, motor racing, motorcycle cycle racing, parachuting, polo, potholing, power boating, racing other than on foot, rugby, show jumping, ski-ing or sledging, water skiing, ice skating, winter sports, wrestling including judo, karate and any other unarmed combat, yachting outside territorial waters and any other hazardous occupations/activities."</p>}
          </div>

          <hr className="my-4 bg-gray-900"   />

          {/* Q4 */}
          {/* Circumstances that would increase risk of accident */}
          <div className="grid grid-cols-1 mb-4">
            <label>Are there any circumstances relating with your occupation, health conditions, habits , pastimes and pursuits which would increase the risk of accident or bodily injury to yourself?</label>
            <RadioGroup name='circumstances' value={formDataStep3PersonalAccidentProduct.radioButtons.circumstances} onChange={handleRadioButtons}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            {formDataStep3PersonalAccidentProduct.radioButtons.circumstances === 'yes' && 
            <TextField label="Give details" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.circumstances}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, circumstances: e.target.value });
              }}
            />}
          </div>

          <hr className="my-4 bg-gray-900"   />

          {/* Q5 */}
          {/* Machinery */}
          <div className="grid grid-cols-1 mb-4">
            <label>In you normal duties, do you use machinery of any kind?</label>
            <RadioGroup name='machinery' value={formDataStep3PersonalAccidentProduct.radioButtons.machinery} onChange={handleRadioButtons}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            {formDataStep3PersonalAccidentProduct.radioButtons.machinery === 'yes' && 
            <TextField label="Give details" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.machinery}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, machinery: e.target.value });
              }}
            />}
          </div>

          <hr className="my-4 bg-gray-900"   />

          {/* Q6 */}
          {/* Medical insurance */}
          <div className="grid grid-cols-1 mb-4">
            <label>Do you have, or have you previously had, a medical insurance cover?</label>
            <RadioGroup name='medicalInsuarance' value={formDataStep3PersonalAccidentProduct.radioButtons.medicalInsuarance} onChange={handleRadioButtons}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            {formDataStep3PersonalAccidentProduct.radioButtons.medicalInsuarance === 'yes' && 
            <TextField label="Give details" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.medicalInsuarance}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, medicalInsuarance: e.target.value });
              }}
            />}
          </div>

          <hr className="my-4 bg-gray-900"   />

          {/* Q7 */}
          {/* Extensive travel */}
          <div className="grid grid-cols-1 mb-4">
            <label>Do you, in the course of your duties, travel extensively by air, car or motor cycle?</label>
            <RadioGroup name='extensiveTravel' value={formDataStep3PersonalAccidentProduct.radioButtons.extensiveTravel} onChange={handleRadioButtons}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            {formDataStep3PersonalAccidentProduct.radioButtons.extensiveTravel === 'yes' && 
            <TextField label="Give details" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.extensiveTravel}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, extensiveTravel: e.target.value });
              }}
            />}
          </div>

          <hr className="my-4 bg-gray-900"   />

          {/* Q8 */}
          {/* Beneficiaries */}
          <div className="grid grid-cols-1 mb-4">
            <h5 className='font-semibold'>Beneficiaries</h5>

            <p>Beneficiary 1</p>
            <TextField label="Name" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.beneficiary1Name}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, beneficiary1Name: e.target.value });
              }}
              style={{ margin: "10px auto" }}
            />
            <TextField label="Age" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.beneficiary1Age}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, beneficiary1Age: e.target.value });
              }}
              style={{ margin: "10px auto" }}
            />
            <TextField label="Relationship" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.beneficiary1Relationship}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, beneficiary1Relationship: e.target.value });
              }}
              style={{ margin: "10px auto" }}
            />
            <p>Beneficiary 2</p>
            <TextField label="Name" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.beneficiary2Name}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, beneficiary2Name: e.target.value });
              }}
              style={{ margin: "10px auto" }}
            />
            <TextField label="Age" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.beneficiary2Age}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, beneficiary2Age: e.target.value });
              }}
              style={{ margin: "10px auto" }}
            />
            <TextField label="Relationship" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.beneficiary2Relationship}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, beneficiary2Relationship: e.target.value });
              }}
              style={{ margin: "10px auto" }}
            />
          </div>
        </form>
      </div>

      <hr className="my-4 bg-gray-900 h-0.5"   />

      {/* Part C */}
      <div className="grid grid-cols-1 mb-2 gap-2">
        <form className="flex-1">
          {/* Personal Accident Policy */}
          <div className="grid grid-cols-1 mb-4">
            <label>Do you at present hold or have you previously held a Personal Accident/Life Insuarance Policy?</label>
            <RadioGroup name='PAPolicy' value={formDataStep3PersonalAccidentProduct.radioButtons.PAPolicy} onChange={handleRadioButtons}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
            {formDataStep3PersonalAccidentProduct.radioButtons.PAPolicy === 'yes' && 
            <TextField label="Give the name of the insurer and the policy number(s)" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.PAPolicy}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, PAPolicy: e.target.value });
              }}
            />}
          </div>

          {/* Personal Accident Declined */}
          <div className="grid grid-cols-1 mb-2">
            <label>Has any insuarance company ever:</label>

            <FormControlLabel
              control={
                <Checkbox
                  name="cancelledPolicy"
                  checked={formDataStep3PersonalAccidentProduct.chkPADeclined.cancelledPolicy}
                  onChange={handleCheckBoxPersonalAccidentDeclined}
                />
              }
              label="cancelled your policy?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="declinedInsuarance"
                  checked={formDataStep3PersonalAccidentProduct.chkPADeclined.declinedInsuarance}
                  onChange={handleCheckBoxPersonalAccidentDeclined}
                />
              }
              label="declined to insure you?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="declinedRenewPolicy"
                  checked={formDataStep3PersonalAccidentProduct.chkPADeclined.declinedRenewPolicy}
                  onChange={handleCheckBoxPersonalAccidentDeclined}
                />
              }
              label="declined to renew your policy?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="specialTerms"
                  checked={formDataStep3PersonalAccidentProduct.chkPADeclined.specialTerms}
                  onChange={handleCheckBoxPersonalAccidentDeclined}
                />
              }
              label="imposed any special terms?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="declinedClaim"
                  checked={formDataStep3PersonalAccidentProduct.chkPADeclined.declinedClaim}
                  onChange={handleCheckBoxPersonalAccidentDeclined}
                />
              }
              label="declined any claim?"
            />

            {isAnyPersonalAccidentDeclinedSelected() &&
            <TextField label="Give details" variant="outlined" fullWidth 
              value={formDataStep3PersonalAccidentProduct.txtPADeclined}
              onChange={(e) => {
              setFormDataStep3PersonalAccidentProduct({ ...formDataStep3PersonalAccidentProduct, txtPADeclined: e.target.value });
              }}
            />}
          </div>
        </form>
      </div>
    </div>
  
  )
}

export default Step1PersonalAccidentProductForm;