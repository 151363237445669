import React, { useState, useEffect } from "react";
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, InputAdornment, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircleOutline from '@mui/icons-material/CircleOutlined';

const Step0HospitalCash = ({
        conversionRates,
        formDataStep0HospitalCash,
        setFormDataStep0HospitalCash,
        errors,
        setErrors,
        HospitalCashPlans,
        today,
        calculatePremium,
        fixedStartDate,
        fixedStartDateProduct,
        formatCurrency,
        policyStartDateError
      }) => {  

        // convertor Function
        const convertAmount = (amount) => {
          // Remove commas and convert to number
          const amountclean = parseFloat(amount.replace(/,/g, ''));
          // Convert to selected currency
          return  (amountclean * conversionRates[formDataStep0HospitalCash.currency]).toFixed(2);
          };

    // Premuim Summary
    const [premiumSummary, setPremiumSummary] = useState({
      premium: 0,
      itl: 0,
      pcf: 0,
      stampDuty: 0,
      total: 0
    })

    const [displayedPlans, setDisplayedPlans] = useState([])
    useEffect(() => {
      if (formDataStep0HospitalCash.coverType === "per-person") {
        setDisplayedPlans(HospitalCashPlans[0]);
      } else if (formDataStep0HospitalCash.coverType === "per-family") {
        setDisplayedPlans(HospitalCashPlans[1]);
      }
    }, [formDataStep0HospitalCash.coverType, HospitalCashPlans]);

    // Set premium summary when component is first rendered
    useEffect(() => {
      setPremiumSummary(calculatePremium(formDataStep0HospitalCash.selectedPlan, formDataStep0HospitalCash.dependantCount, displayedPlans))
    }, [formDataStep0HospitalCash, displayedPlans]);

    // Deselect all previous Selected plans
    const handleDeselectPlans = () => {
      setFormDataStep0HospitalCash(prevState => ({
        ...prevState,
          selectedPlan: null,
          totalPremium: null,
      }));
    }
    

    return (
      <div className="bg-white p-2 shadow-div shadow-3xl  mt-1">
        <form className="mt-4">
          <hr className="my-4 bg-gray-900"   />
          <div className='mb-3'>
            <h5 className='font-semibold'>Hospital Cash</h5>  
          </div>
  
          {/* Cover Type */}
          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 mb-2 gap-2">
            
            {/* <div className="flex-1 self-end">
              <FormControl fullWidth variant="outlined" error={!!errors.coverType}>
                <InputLabel id="cover-type-label">Cover Type</InputLabel>
                <Select
                  labelId="cover-type-label"
                  id="cover-type"
                  label="Cover Type"
                  value={formDataStep0HospitalCash.coverType}
                  onChange={(e) => {
                    setFormDataStep0HospitalCash({ ...formDataStep0HospitalCash, coverType: e.target.value });
                    if (formDataStep0HospitalCash.selectedPlan) {
                      setFormDataStep0HospitalCash({ ...formDataStep0HospitalCash, selectedPlan: null})
                    }  
                    if (errors.coverType) {
                      setErrors({ ...errors, coverType: '' });
                    }
                  }}
                >
                  <MenuItem value="per-person">Per-person Cover</MenuItem>
                  <MenuItem value="per-family">Per-family Cover (Shared)</MenuItem>
                </Select>
                {errors.coverType && (
                  <FormHelperText sx={{ fontSize: '0.75rem', color: 'error.main' }}>
                    {errors.coverType}
                  </FormHelperText>
                )}
              </FormControl>
            </div> */}
  
            {/* Dependant Count */}
            <div className="flex-1 self-end">
              <TextField
                // required
                id="dependant-count"
                label="Dependant Count"
                variant="outlined"
                fullWidth
                type="number"
                value={formDataStep0HospitalCash.dependantCount}
                onChange={(e) => {
                  setFormDataStep0HospitalCash({ ...formDataStep0HospitalCash, dependantCount: e.target.value });
                  if (formDataStep0HospitalCash.selectedPlan) {
                    setFormDataStep0HospitalCash({ ...formDataStep0HospitalCash, selectedPlan: null})
                  }    
                  if (errors.dependantCount) {
                    setErrors({ ...errors, dependantCount: '' });
                  }
                }}
                error={!!errors.dependantCount}
                helperText={errors.dependantCount}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </div>
  
            {/* Policy Start Date */}
            <div className="flex-1 self-end">
              <TextField
                id="policy-start-date"
                label="Policy Start Date"
                variant="outlined"
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={!!fixedStartDate && fixedStartDateProduct !== 'hospitalCash' ? fixedStartDate : formDataStep0HospitalCash.policyStartDate}
                disabled={!!fixedStartDate && fixedStartDateProduct !== 'hospitalCash'}
                onChange={(e) => {
                  const newDate = e.target.value;
                  setFormDataStep0HospitalCash({ ...formDataStep0HospitalCash, policyStartDate: newDate });
                  if (errors.policyStartDate) {
                    setErrors({ ...errors, policyStartDate: '' });
                  }
                }}
                InputProps={{
                  inputProps: { min: today },
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <IconButton edge="end">
                  //       <CalendarTodayIcon />
                  //     </IconButton>
                  //   </InputAdornment>
                  // ),
                }}
                error={!!policyStartDateError}
                helperText={policyStartDateError}
                  sx={{ mt: 1 }}
              />
            </div>
          </div>
  
          {/* Product Options Table */}
          {formDataStep0HospitalCash.coverType !== '' &&
            <div className="mt-6">
              <h5 className=" font-semibold mb-2">Product Options</h5>
              <div className="overflow-x-auto">
                <div className="min-w-full border border-gray-300">
                  <div className="Table-titles grid grid-cols-4 gap-0 text-center font-semibold border-b-2 border-gray-300 text-white bg-gray-600">
                    {/* <div className="border border-gray-300 p-2 Product_ID">Product ID</div> */}
                    <div className="border border-gray-300 p-2">Plan</div>
                    <div className="border border-gray-300 p-2">Cover Amount</div>
                    <div className="border border-gray-300 p-2">Premium (Base)</div>
                    <div className="border border-gray-300 p-2">Select a Plan</div>
                  </div>
                  {displayedPlans.map((plan) => (
                    <div
                      key={plan.product_Id}
                      className={`
                        Table-datas grid grid-cols-4 gap-0 text-center border-b border-gray-300
                        ${plan.product_Id === formDataStep0HospitalCash.selectedPlan ? 'bg-green-700 text-white' : ''}
                      `}
                      onClick={() => {
                        if (!formDataStep0HospitalCash.selectedPlan !== plan.product_Id) {
                          setPremiumSummary(calculatePremium(plan.product_Id, formDataStep0HospitalCash.dependantCount, displayedPlans));
                          setFormDataStep0HospitalCash({
                            ...formDataStep0HospitalCash,
                            selectedPlan: plan.product_Id,
                            totalPremium: calculatePremium(plan.product_Id, formDataStep0HospitalCash.dependantCount, displayedPlans).total
                          })
                        } else {
                          handleDeselectPlans();
                        }

                        setErrors(prevErrors => ({
                          ...prevErrors,
                          selectedPlan: '',  
                        }));
                        console.log(calculatePremium(plan.product_Id, formDataStep0HospitalCash.dependantCount, displayedPlans));
                        console.log(plan.product_Id);
                        console.log(formDataStep0HospitalCash.dependantCount);
                        console.log(displayedPlans);
                      }}
                    >
                      {/* <div className="border border-gray-300 p-2 Product_ID">{plan.product_Id}</div> */}
                      <div className="border border-gray-300 p-2">{plan.plan_Name}</div>
                      <div className="border border-gray-300 p-2">{formatCurrency(convertAmount(String(plan.cover_Amount)))}</div>
                      <div className="border border-gray-300 p-2">{formatCurrency(convertAmount(String(plan.premium)))}</div>
                      <div className="border border-gray-300 p-2">
                        {plan.product_Id === formDataStep0HospitalCash.selectedPlan &&
                          <CheckCircleOutlineIcon /> 
                        }
                        {plan.product_Id !== formDataStep0HospitalCash.selectedPlan &&
                          <CircleOutline />
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {errors.selectedPlan && (
                <p className="text-red-500 text-sm mt-2">{errors.selectedPlan}</p>
              )}
            </div>
          }
  
          {/* Container for two-column layout on large screens */}
          <div className="mt-6 flex flex-col lg:flex-row lg:gap-6">
            {/* Product Summary */}
            {formDataStep0HospitalCash.selectedPlan && (
                <div className="flex flex-col p-4 border rounded-lg shadow-md bg-white lg:w-1/2">
                  <div className="border-b border-gray-300 pb-2 mb-2">
                    <h2 className="text-xl font-semibold mb-4">Premium Summary</h2>
                  </div>
                  <div className="flex flex-col gap-4 text-sm text-gray-700">
                    {/* Display summary based on selected plan */}
                    <div>
                      <h3 className="text-base font-medium mb-4">Hospital Cash Premium Summary</h3>
                      <div className="flex flex-col gap-2 text-sm text-gray-700 border-t ">
                        <div className="flex justify-between border-b  pt-2 border-gray-300 pb-2 mb-2">
                          <span className="font-medium">Premium</span>
                          <span>{premiumSummary.premium || 'N/A'}</span>
                        </div>
                        <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">ITL</span>
                          <span>{premiumSummary.itl || 'N/A'}</span>
                        </div>
                        <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">PCF</span>
                          <span>{premiumSummary.pcf || 'N/A'}</span>
                        </div>
                        <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">Stamp Duty</span>
                          <span>{premiumSummary.stampDuty || 'N/A'}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="font-medium">TOTAL</span>
                          <span><b>{premiumSummary.total || 'N/A'}</b></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

  
            {/* Disclaimer */}
            <div className={`lg:w-1/2 ${formDataStep0HospitalCash.selectedPlan ? 'lg:pl-6' : ''}`}>
              <p className="text-sm text-gray-600 mt-6 lg:mt-0">
                <b>DISCLAIMER:</b> The premium shown is approximate and should be used for illustrative and general information purposes only. The final premium amount may change and will be determined accurately after further assessment.
              </p>
            </div>
          </div>
        </form>
      </div>
    );
  };

 export default Step0HospitalCash;