import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Step4MpesaScreen from '../components/Step4MpesaScreen';
import Step4StripePayment from '../components/Step4StripePayment';

const Step4Payment = ({ 
  checkedAddProductItems,
  formDataStep0EvacuationRepatriation,
  formDataStep0LastExpense,
  formDataStep0Medical,
  setFormDataStep0Medical,
  formDataStep0HospitalCash,
  formDataStep0PersonalAccident,
  contactAndLoginsAndCurrency,
  formatCurrency,
  activeStep,
  setActiveStep,
  email
}) => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [screen, setScreen] = useState('paymentHome')

  const [paymentSuccess, SetPaymentSuccess]  = useState(false);

  const handleRadioButtonsChange = (e) => {
    setPaymentMethod(e.target.value)
  };

  const handlePayButtonClick = () => {
    if (paymentMethod === 'mpesa') {setScreen('mpesa');}
    else if (paymentMethod === 'stripe') {setScreen('stripe');}
    else if (paymentMethod === '') {alert("Select a payment method");}
  };

  // Clean currency object function
  const cleanCurrencyObject = (amount) => {
    const cleanAmount = (parseFloat((amount).replace(/Ksh\s?|£|€|\$/g, '').replace(/,/g, ''))).toFixed(4);

    return cleanAmount;
  }

  const calculateTotalPremium = () => {
    console.log("Inpatient Premium:", formDataStep0Medical.totalInpatientPremium);
    console.log("Outpatient Premium:", formDataStep0Medical.totalOutpatientPremium);
    console.log("Dental Premium:", formDataStep0Medical.totalDentalPremium);
    console.log("Optical Premium:", formDataStep0Medical.totalOpticalPremium);

    setFormDataStep0Medical({
      ...formDataStep0Medical,
      totalPremium: 
      formatCurrency(      
      [
        formDataStep0Medical.totalInpatientPremium ? cleanCurrencyObject(formDataStep0Medical.totalInpatientPremium) : 0,
        formDataStep0Medical.totalOutpatientPremium ? cleanCurrencyObject(formDataStep0Medical.totalOutpatientPremium) : 0,
        formDataStep0Medical.totalDentalPremium ? cleanCurrencyObject(formDataStep0Medical.totalDentalPremium) : 0,
        formDataStep0Medical.totalOpticalPremium ? cleanCurrencyObject(formDataStep0Medical.totalOpticalPremium) : 0,
      ]
        .map(value => parseFloat(value) || 0) // Convert to float and use 0 if NaN
        .reduce((acc, curr) => acc + curr, 0) // Sum up the values
        .toFixed(2) // Format the result to 2 decimal places
      )    
    });

    return [
      checkedAddProductItems_payment.evacuationRepatriation ? cleanCurrencyObject(formDataStep0EvacuationRepatriation.totalPremium) : 0,
      checkedAddProductItems_payment.lastExpense ? cleanCurrencyObject(formDataStep0LastExpense.totalPremium) : 0,
      checkedAddProductItems_payment.medical ? cleanCurrencyObject(String(formDataStep0Medical.totalPremium)) : 0,
      checkedAddProductItems_payment.hospitalCash ? cleanCurrencyObject(formDataStep0HospitalCash.totalPremium) : 0,
      checkedAddProductItems_payment.personalAccident ? cleanCurrencyObject(formDataStep0PersonalAccident.totalPremium) : 0
    ]
      .map(value => parseFloat(value) || 0) // Convert to float and use 0 if NaN
      .reduce((acc, curr) => acc + curr, 0) // Sum up the values
      .toFixed(2) // Format the result to 2 decimal places
  }

  const [checkedAddProductItems_payment, setCheckedAddProductItems_payment] = useState(checkedAddProductItems);
  // Define a function to handle checkbox changes
  const handleCheckboxAddProduct_payment = (event) => {
    const { name, checked } = event.target;
    setCheckedAddProductItems_payment((prevCheckedAddProductItems_payment) => ({
      ...prevCheckedAddProductItems_payment,
      [name]: checked,
    }));
  };

  const [totalPremium, setTotalPremium] = useState(0);
  useEffect(() => {
    setTotalPremium(calculateTotalPremium());
  }, []);

  console.log('**** Currency and Total Premium');
  console.log(contactAndLoginsAndCurrency.currency);
  console.log(totalPremium);


  return (
    <>
      {screen === 'paymentHome' &&
      <div className="shadow-div grid grid-cols-2">
        <div className="border-r pt-2 border-gray-300 pr-5 mr-5">
          <h5 className='font-semibold mb-2'>Selected Product(s)</h5>

          {checkedAddProductItems.evacuationRepatriation ? 
          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="evacuationRepatriation"
                  checked={checkedAddProductItems_payment.evacuationRepatriation}
                  onChange={handleCheckboxAddProduct_payment}
                />
              }
              label="Evacuation and Repatriation"
            />
            <span>{formDataStep0EvacuationRepatriation.totalPremium}</span>
          </div>
          : null}
          {checkedAddProductItems.lastExpense ? 
          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="lastExpense"
                  checked={checkedAddProductItems_payment.lastExpense}
                  onChange={handleCheckboxAddProduct_payment}
                />
              }
              label="Last Expense"
            />
            <span>{formDataStep0LastExpense.totalPremium}</span>
          </div>
          :null}
          {checkedAddProductItems.medical ? 
          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="medical"
                  checked={checkedAddProductItems_payment.medical}
                  onChange={handleCheckboxAddProduct_payment}
                />
              }
              label="Medical"
            />
            <span>{formDataStep0Medical.totalPremium}</span>
          </div>
          :null}
          {checkedAddProductItems.hospitalCash ? 
          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="hospitalCash"
                  checked={checkedAddProductItems_payment.hospitalCash}
                  onChange={handleCheckboxAddProduct_payment}
                />
              }
              label="Hospital Cash"
            />
            <span>{formDataStep0HospitalCash.totalPremium}</span>
          </div>
          :null}
          {checkedAddProductItems.personalAccident ? 
          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="personalAccident"
                  checked={checkedAddProductItems_payment.personalAccident}
                  onChange={handleCheckboxAddProduct_payment}
                />
              }
              label="Personal Accident"
            />
            <span>{formDataStep0PersonalAccident.totalPremium}</span>
          </div>
          :null}

          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
            <span className='font-semibold mb-2'>Total: </span>
            <span>{formatCurrency(totalPremium)}</span>
          </div>
        </div>

        <div className="pt-2 pr-5 mr-5 flex items-center flex-col">
          <h5 className='font-semibold mb-2'>Payment Method</h5>

          <FormControl className="flex-grow">
            <RadioGroup value={paymentMethod} onChange={handleRadioButtonsChange}>
              <FormControlLabel value="mpesa" control={<Radio disabled={contactAndLoginsAndCurrency.currency !== 'KES'}/>} label="M-Pesa" />
              <FormControlLabel value="pesapal" control={<Radio />} label="Pesapal" />
              <FormControlLabel value="stripe" control={<Radio />} label="Stripe" />
            </RadioGroup>
          </FormControl>

          <button
            type="button"
            style={{backgroundColor:'#E42D2C', marginLeft:20}}
            className="text-white text-sm px-3 py-1 rounded w-20"
            onClick={handlePayButtonClick}
          >
            Pay
          </button>
        </div>
      </div>
      }

      {screen === 'mpesa' &&
        <Step4MpesaScreen />
      }
      {screen === 'stripe' &&
        <>
          <Step4StripePayment
            SetPaymentSuccess={SetPaymentSuccess}
            currency={contactAndLoginsAndCurrency.currency}
            premiumAmount={totalPremium}
            email={email}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </>
      }
    </>
  )
}

export default Step4Payment;
