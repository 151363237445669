import React, { useState, useEffect } from "react";
import {
  Select,
  Autocomplete,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from "@mui/material";
import Step1PersonalData from "./Step1PersonalData";
import Step1PersonalAccidentProductForm from '../components/Step1PersonalAccidentProductForm';

const Step2MedicalHistory = ({ 
  formDataStep1CustomerPersonalDetails,
  formDataStep3MedicalHistory,
  setFormDataStep3MedicalHistory,
  step2Next,
  setStep2Next,
  step2Back,
  setStep2Back,
  formDataStep0EvacuationRepatriation,
  formDataStep0LastExpense,
  formDataStep0Medical,
  formDataStep0HospitalCash,
  formDataStep0PersonalAccident,
  medicalHistoryReferDecline,
  setMedicalHistoryReferDecline,
  handleNext,
  selectedPlans,
  loadMultiplier,
  setLoadMultiplier,
  formDataStep3PersonalAccidentProduct,
  setFormDataStep3PersonalAccidentProduct,
  checkedAddProductItemsPersonalAccident,
  errorsStep2PersonalAccidentForm,
  setErrorsStep2PersonalAccidentForm,
}) => {
  // Fetch questionaire data
  const [questionaireData, setQuestionaireData] = useState([]);
  useEffect(() => {
    fetch('https://quote.birdviewmicroinsurance.com/api/product/Questionnaires')
        .then(response => response.json())
        .then(data => {
          setQuestionaireData(data);
        })
        .catch(error => console.error('Error loading JSON questionaire data:', error));
  }, []);

  const initialDependantDetails = () => {
    const savedData = localStorage.getItem("DependantDetailsStep1");
    if (savedData) {
      return JSON.parse(savedData);
    }
  };

  const [DependantDetailsStep2, setDependantDetailsStep2] = useState(
    initialDependantDetails
  );

  // const calculateTotalPremium = () => {
  //   return [
  //     checkedAddProductItems_payment.evacuationRepatriation ? formDataStep0EvacuationRepatriation.totalPremium : 0,
  //     checkedAddProductItems_payment.lastExpense ? formDataStep0LastExpense.totalPremium : 0,
  //     checkedAddProductItems_payment.medical ? formDataStep0Medical.totalPremium : 0,
  //     checkedAddProductItems_payment.hospitalCash ? formDataStep0HospitalCash.totalPremium : 0,
  //     checkedAddProductItems_payment.personalAccident ? formDataStep0PersonalAccident.totalPremium : 0
  //   ]
  //     .map(value => parseFloat(value) || 0) // Convert to float and use 0 if NaN
  //     .reduce((acc, curr) => acc + curr, 0) // Sum up the values
  //     .toFixed(2) // Format the result to 2 decimal places
  // }

  // Function to check if any checkbox is checked for a given question index
  // const isAnyCheckboxCheckedForQuestion = (qIndex) => {
  //   // Check principal person's checkbox
  //   if (questionaireCheckboxValues[`${qIndex}-0`] === true) {
  //     return true;
  //   }
  //   // Check dependants' checkboxes
  //   return DependantDetailsStep2.some((_, dIndex) => 
  //     questionaireCheckboxValues[`${qIndex}-${dIndex + 1}`] === true
  //   );
  // };

  // Function to check if any checkbox is checked across all questions
  const isAnyCheckboxChecked = () => {
    return Object.values(questionaireCheckboxValues).some(checked => checked === true);
  };


  const [isMedicalHistoryReferDeclineUpdated, setIsMedicalHistoryReferDeclineUpdated] = useState(false);



  useEffect(() => {
    // Only execute the effect if step2Next is true
    if (step2Next) {
      let newRefer = false;
      let newDecline = false;
  
      let newLoadMultiplier = 1;

      questionaireData.forEach((question, qIndex) => {
        // Check the principal's checkbox
        if (questionaireCheckboxValues[`${qIndex}-0`] === true) {
          if (question.v_Action.includes('Load')) {
            newLoadMultiplier += question.loading / 100;
          }
          if (question.v_Action.includes('Refer')) {
            newRefer = true;
          }
          if (question.v_Action.includes('Decline')) {
            newDecline = true;
          }
        }

        // Check the dependants' checkboxes
        DependantDetailsStep2.forEach((_, dIndex) => {
          if (questionaireCheckboxValues[`${qIndex}-${dIndex + 1}`] === true) {
            if (question.v_Action.includes('Load')) {
              newLoadMultiplier += question.loading / 100;
            }
            if (question.v_Action.includes('Refer')) {
              newRefer = true;
            }
            if (question.v_Action.includes('Decline')) {
              newDecline = true;
            }
          }
        });
      });
      setLoadMultiplier(newLoadMultiplier);
  
      setMedicalHistoryReferDecline({
        ...medicalHistoryReferDecline,
        refer: newRefer,
        decline: newDecline,
      });
  
      // Reset step2Next
      setStep2Next(false);
      setIsMedicalHistoryReferDeclineUpdated(true);
    }
  }, [step2Next, questionaireData, medicalHistoryReferDecline]);
  useEffect(() => {
    if (isMedicalHistoryReferDeclineUpdated) {
      // Ensure handleNext is called only after `medicalHistoryReferDecline` is updated
      if (!(medicalHistoryReferDecline.refer || medicalHistoryReferDecline.decline)) {
        handleNext();
      }
      setIsMedicalHistoryReferDeclineUpdated(false);
    }
  }, [medicalHistoryReferDecline, step2Next]);
  useEffect(() => {
    // Reset medicalHistoryReferDecline when back is clcked
    if (step2Back) {
      setMedicalHistoryReferDecline({
        ...medicalHistoryReferDecline,
        refer: false,
        decline: false,
      });
      setIsMedicalHistoryReferDeclineUpdated(false)

      // Reset step2Back
      setStep2Back(false);
    }
  }, [step2Back])

  // Retrieve stored checkbox values from localStorage or default to empty object
  const [questionaireCheckboxValues, setQuestionaireCheckboxValues] = useState(() => {
    const saved = localStorage.getItem('questionaireCheckboxValues');
    return saved ? JSON.parse(saved) : {};
  });

  useEffect(() => {
    // Store checkbox values in localStorage whenever they change
    localStorage.setItem('questionaireCheckboxValues', JSON.stringify(questionaireCheckboxValues));
  }, [questionaireCheckboxValues]);

  const handleQuestionaireCheckboxChange = (qIndex, dIndex) => (event) => {
    const updatedValues = { ...questionaireCheckboxValues, [`${qIndex}-${dIndex}`]: event.target.checked };
    setQuestionaireCheckboxValues(updatedValues);
    setFormDataStep3MedicalHistory(questionaireCheckboxValues);
  };

  // const [combinedMedicalPlan, setCombinedMedicalPlan] = useState({}); 
  // for (let i = 0; i < selectedPlans.length; i++) {
  //   if (selectedPlans[i].product_Id === 'm') {
  //     setCombinedMedicalPlan(selectedPlans[i])
  //   }
  // }
  

  return(
    <>
      {!medicalHistoryReferDecline.refer && !medicalHistoryReferDecline.decline &&
      <div className="mt-6">
        <div className="shadow-div mt-4">
          <h5 className="font-semibold mb-2">Medical History Questionnaire</h5>
          <p className="mb-2">Please tick if you suffer from any of the following conditions.</p>
          <div className="overflow-x-auto">
            <table className="min-w-full border border-gray-300">
              <thead className="bg-gray-600 text-white">
                <tr>
                  <th className="border border-gray-300 p-2">Question No.</th>
                  <th className="border border-gray-300 p-2">Questionnaire</th>
                  {/* <th className="border border-gray-300 p-2">{formDataStep1CustomerPersonalDetails.firstName} {formDataStep1CustomerPersonalDetails.surname}</th> */}
                  <th className="border border-gray-300 p-2">{formDataStep1CustomerPersonalDetails.firstName}</th>
                  {DependantDetailsStep2.map((dependant, index) => (
                    <th key={index} className="border border-gray-300 p-2">
                      {/* {dependant.firstName} {dependant.surname} */}
                      {dependant.firstName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {questionaireData.map((question, qIndex) => (
                  <tr key={qIndex}>
                    <td className="border border-gray-300 p-2">{question.questionnaire_No}</td>
                    <td className="border border-gray-300 p-2">{question.questionnaire}</td>
                    <td className="border border-gray-300 p-2">
                      <FormControlLabel
                      control={
                        <Checkbox
                        checked={questionaireCheckboxValues[`${qIndex}-0`] || false}
                        onChange={handleQuestionaireCheckboxChange(qIndex, 0)}
                        />
                      }
                      label=""
                      />
                    </td>
                    {DependantDetailsStep2.map((dependant, dIndex) => (
                      <td key={dIndex} className="border border-gray-300 p-2">
                        <FormControlLabel
                        control={
                            <Checkbox
                            checked={questionaireCheckboxValues[`${qIndex}-${dIndex + 1}`] || false}
                            onChange={handleQuestionaireCheckboxChange(qIndex, dIndex + 1)}
                            />
                        }
                        label=""
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* {errors.selectedPlan && (
            <p className="text-red-500 text-sm mt-2">{errors.selectedPlan}</p>
          )} */}
        </div>

        { // Personal Accident Product Form
          checkedAddProductItemsPersonalAccident === true &&  
          <Step1PersonalAccidentProductForm
            formDataStep3PersonalAccidentProduct={formDataStep3PersonalAccidentProduct}
            setFormDataStep3PersonalAccidentProduct={setFormDataStep3PersonalAccidentProduct}
            errors={errorsStep2PersonalAccidentForm}
            setErrors={setErrorsStep2PersonalAccidentForm}
          />
        }  

      </div>
      }

      {medicalHistoryReferDecline.refer && !medicalHistoryReferDecline.decline &&
      <>
        <div className="shadow-div">Based on your medical history, you are requested to refer to the underwriter. <br /> Contact info: [contact info]
        
          <div className="flex justify-end">
            <Button
              onClick={() => { window.location.href = "https://birdviewmicroinsurance.com/" }}
              color="error" 
              variant="contained"  
            >
              OK
            </Button>
          </div>
        </div>
        
      </>
      }

      {medicalHistoryReferDecline.decline &&
      <>
        <div className="shadow-div">Dear {formDataStep1CustomerPersonalDetails.firstName},
          Thank you for considering us as your potential insurer. We truly value your interest in our [Product Name] product(s).
          After assessment of your application, we are unable to proceed with this quotation. Kindly contact our customer service team on customerservice@birdviewinsurance.com or +254 742 222 888 for further discussion.
        
          <div className="flex justify-end">
            <Button
              onClick={() => { window.location.href = "https://birdviewmicroinsurance.com/" }}
              color="error" 
              variant="contained"  
            >
              OK
            </Button>
          </div>      
        </div>

      </>
      }
    </>
  )
}

export default Step2MedicalHistory;
