import React, { useState, useEffect } from "react";
import Step5PolicyDodument from '../components/Step5PolicyDodument';


const Step5ConfirmationLetter = ({ 
  today,
  selectedPlans,
  formDataStep1CustomerPersonalDetails,
  fixedStartDate
}) => {
  const processState = "download";
  const buttonName = "Download Policy Document";

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Get day with leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month with leading zero (months are 0-based)
    const year = date.getFullYear(); // Get full year
    return `${month}/${day}/${year}`;
  }

  const addCoverPeriod = (productData) => {
    let startDate = new Date(fixedStartDate);
    let endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 364);
    startDate = formatDate(startDate);
    endDate = formatDate(endDate);
    return `${startDate} to ${endDate}`
  }

  const todayObject = new Date(today);
  const selectedPlansStr = selectedPlans.map(element => (
    element.benefit
  )).join(', ')
  console.log(selectedPlans)

  const getDependantDetails = () => {
    const savedData = localStorage.getItem("DependantDetailsStep1");
    if (savedData) {
      return JSON.parse(savedData);
    }
  };
  const dependantDetails = getDependantDetails();


  return (
    <div className="shadow-div flex flex-col">
      <h5 className='font-semibold mb-2'>Confirmation Letter</h5>
      <h5 className='font-semibold mb-2'>PRIVATE & CONFIDENTIAL</h5>

      <label>{formatDate(todayObject)}</label>
      <label>{formDataStep1CustomerPersonalDetails.firstName} {formDataStep1CustomerPersonalDetails.middleName} {formDataStep1CustomerPersonalDetails.surname}</label>
      <label>{selectedPlansStr}</label>
      <label>P.O.Box 45157 - 00200 Nairobi, Kenya</label>

      <p className="m-1">RE: {selectedPlansStr} COVER CONFIRMATION [policy no]</p>
      <p className="m-1">Welcome to Birdview Microinsurance Limited. We thank you for selecting us as your Medical insurance provider of choice.</p>
      <p className="m-1">We are pleased to inform you that your application has been accepted. Please note the following:</p>
      <p className="m-1">Insurance Period: {addCoverPeriod()}</p>
      <p className="m-1 flex flex-row">
        1. Name(s) of persons covered: {formDataStep1CustomerPersonalDetails.firstName}{dependantDetails.map((dependant, index) => (<div>, {dependant.firstName}</div>))}
      </p>
      <p className="m-1">2. Plan(s) Purchased: {selectedPlansStr}</p>
      <p className="m-1">3. There is a general waiting period of thirty (30) days from the cover commencement date. This is a period of time that commences on your policy start date, during which time you will only be entitled to treatment resulting from accidents and not illness.</p>
      <p className="m-1">4.	Your membership has been accepted on standard terms subject to the exclusions and waiting period as per the attached policy document.</p>
      <p className="m-1">5.	This policy is subject to sub-limits, waiting periods and other terms and conditions. We would kindly request you to go through the same carefully so as to better understand what you are covered for, how to access the benefits and the applicable terms and conditions of this policy.  Please do not hesitate to contact us if you need any further information and/or clarification regarding any aspect of this policy.</p>
      
      <p className="m-1">To ensure seamless access to services and high-quality health care, note that you can only access credit for medical benefits at our provider panel attached.</p>
      <p className="m-1">Scheduled admissions must be pre-authorized at least 48 hours before admission. In case of emergency admissions, the hospital must contact Birdview Microinsurance Limited within 48 hours</p>
      <p className="m-1">Please acknowledge receipt of this letter as your acceptance of the terms and conditions to enable us to finalize processing your membership. If we do not receive the acknowledgement of this letter within 14 days from the above dated, it will be deemed that you have accepted the policy terms and conditions</p>
      <p className="m-1">We wish you continued good health and thank you for choosing Birdview Microinsurance Limited.</p>
      <p className="mx-1">Yours faithfully,</p>
      <br />
      <br />
      <br />
      <br />
      <p className="m-1">XXXXXX XXXXXXXXX (NAME)</p>
      <p className="m-1">XXXXXXXX XXXXXXXXXXX (DESIGNATION)</p>
      <p className="m-1">c.c:  XXX XXXXXXX Brokers</p>
      <br />

      <div className="mt-4"> {/* Add some margin above the button */}
        <Step5PolicyDodument processState={processState} buttonName={buttonName} />
      </div>
    </div>
  )
}

export default Step5ConfirmationLetter;
